
import React from "react"

import PropTypes from "prop-types"

import Rating from "./rating"

function Testimonial({ text, rating, personImage, personName, personTitle, }) {
  return (
    <article className="flex flex-col items-center gap-4 text-center md:flex-row md:gap-[2.4rem] md:text-left">
      <img className="rounded-full shadow-md" src={personImage} alt={personName} width="100" height="100" loading="lazy" />
      <div className="w-full text-black max-w-242">
        <blockquote className="italic">{text}</blockquote>
        <div className="flex flex-col items-center gap-2 mt-5 md:items-start lg:flex-row lg:items-center lg:justify-between">
          <span className="text-xl font-bold">
            <span className="text-rose-400">{personName}</span> - <span className="capitalize">{personTitle}</span>
          </span>
          <Rating rating={rating} />
        </div>
      </div>
    </article>
  )
}

Testimonial.defaultProps = {
  rating: 5
}

Testimonial.propTypes = {
  text: PropTypes.string.isRequired,
  rating: PropTypes.number,
  personName: PropTypes.string.isRequired,
  personTitle: PropTypes.string.isRequired,
  personImage: PropTypes.node.isRequired,
}

export default Testimonial
