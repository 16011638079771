
import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import DefaultBackground from "../content/courses/images/backgrounds/default.svg"

function CourseBackground({ image }) {

  const data = useStaticQuery(graphql` 
    query {
      allFile(filter: {
        sourceInstanceName: {eq: "courses"}
        extension: {eq: "svg"}
        relativeDirectory: {eq: "images/backgrounds"}
      }
      ) {
        edges {
          node {
            id
            base
            publicURL
          }
        }
      }
    }
  `)

  let imageFile = null
  data.allFile.edges.forEach(edge => {
    if (edge.node.base == image + ".svg") {
      imageFile = edge.node.publicURL
    }
  })

  if (imageFile) {
    return (
      <img className="mx-auto" src={imageFile} alt="course logo" width="574" height="436" loading="lazy" />
    )
  } else {
    return (
      <img className="mx-auto" src={DefaultBackground} alt="course logo" width="574" height="436" loading="lazy" />
    )
  }

}

export default CourseBackground
