
import React from "react"

import PropTypes from "prop-types"
import StarYellow from "./icons/star-yellow.svg"
import StarBlue from "./icons/star-blue.svg"


function Rating({ rating, size, style, className }) {

  return (
    <span className={className}>
      {Array.from(Array(rating), (e, i) => {
        return (
          <img
            key={"star-" + i}
            src={style == "yellow" ? StarYellow : StarBlue}
            alt="*"
            width={size}
            height={size}
          />
        )
      })}
    </span>
  )

}

Rating.defaultProps = {
  rating: 5,
  size: 26,
  style: "yellow",
  className: "flex gap-x-1.5 mt-2 lg:mt-0"
}

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  size: PropTypes.number,
  style: PropTypes.oneOf(['yellow', 'blue']),
  className: PropTypes.string
}

export default Rating
