import React from "react"

function StickySection({isVisible}) {
  return (
      <section className="fullbleed sticky-section fixed h-screen top-0 left-0 pointer-events-none">
          <div className={'container bg-sky-900 absolute w-full bottom-0 pt-5 pb-5 flex justify-between' + (!isVisible ? ' visible' : '')}>
            <a
              className="pointer-events-auto flex max-h-22.5 flex-1 items-center justify-center bg-rose-400 p-9 text-2xl font-bold uppercase text-white duration-200 hover:bg-rose-500 xl:flex-none"
              href="#pricing"
            >
              Book Now
            </a>
            <a
              className="pointer-events-auto flex max-h-22.5 flex-1 items-center justify-center bg-rose-400 p-9 text-2xl font-bold uppercase text-white duration-200 hover:bg-rose-500 xl:flex-none"
              href="/contact"
            >
              Ask a question
            </a>
        </div>
        
      </section>
  )
}

export default StickySection
