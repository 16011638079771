
import React from "react"

import Diagonal from "./icons/diagonal.svg"

function PriceBox({ name, price, oldPrice, comment1, comment2, bookingLink }) {
  return (
    <div className="grid w-full justify-items-center border-2 border-sky-900 pt-4 pb-9 text-center md:grid-cols-2 lg:max-w-87.5 lg:grid-cols-1">
      <div className="w-full py-5 text-3xl text-white bg-sky-900 md:col-span-full">
        <h3>{name}</h3>
      </div>
      <div className="grid">
        {oldPrice ?
          <del className="relative mt-8 text-[40px] font-bold text-neutral-300 no-underline">
            <img src={Diagonal} className="absolute z-10 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2" alt="discounted" width="87" height="53" />
            {oldPrice}€
          </del>
          : <del className="relative mt-8 text-[40px] font-bold text-neutral-300 no-underline">&nbsp;</del>
        }
        <span className="mt-9 text-6.5xl font-bold md:mt-4 lg:mt-9">{price}€</span>
        <span className="mt-1 text-xl" dangerouslySetInnerHTML={{ __html: comment1 }} />
        <span className="mt-1 text-xl" dangerouslySetInnerHTML={{ __html: comment2 }} />
      </div>
      <a target="_blank" className="px-10 mt-12 text-2xl font-bold text-white uppercase duration-200 bg-rose-400 py-9 hover:bg-rose-500 md:self-end" href={bookingLink}>Book Now</a>
    </div>
  )
}

export default PriceBox
