
import React, {useEffect, useRef, useState} from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Rating from "../components/rating"
import Icon from "../components/icon"
import Testimonial from "../components/testimonial"
import PriceBox from "../components/price-box"
import CourseBackground from "../components/course-background"
import StickySection from "../components/sticky-section"

import Circle from "../components/icons/circle.svg"
import Sold from "../components/icons/sold.svg"


function CourseTemplate({ location, data }) {

  // TODO: add product here: https://developers.google.com/search/docs/advanced/structured-data/product
  // TODO: add event data: https://developers.google.com/search/docs/advanced/structured-data/event
  // TODO: add course data: https://developers.google.com/search/docs/advanced/structured-data/course

  // TODO: add FAQ: https://developers.google.com/search/docs/advanced/structured-data/faqpage
  // TODO: add online activity here: https://developers.google.com/search/docs/advanced/structured-data/home-activities

  const [isButtonInView, setIsButtonInView] = useState(true);
  const [isPricesInView, setIsPricesInView] = useState(true);
  const buttonRef = useRef();
  const pricesRef = useRef();

  const checkButtonVisibility = (entries, observer) => {
    entries.forEach(entry => {
      setIsButtonInView(entry.isIntersecting);
    });
  };
  const checkPriceVisibility = (entries, observer) => {
    entries.forEach(entry => {
      setIsPricesInView(entry.isIntersecting);
    });
  };

  useEffect(() => {
    const buttonObserver = new IntersectionObserver(checkButtonVisibility, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    const pricesObserver = new IntersectionObserver(checkPriceVisibility, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });

    if (buttonRef.current) {
      buttonObserver.observe(buttonRef.current);
    }
    if (pricesRef.current) {
      pricesObserver.observe(pricesRef.current);
    }


    return () => {
      if (buttonRef.current) {
        buttonObserver.unobserve(buttonRef.current);
      }
      if (pricesRef.current) {
        pricesObserver.unobserve(pricesRef.current);
      }
    };
  }, []);

  const course = data.coursesYaml

  const firstCourse = course.dates && course.dates.length > 0 ? 
    course.dates
      .sort((a, b) => (a.startDate > b.startDate))
      .find(c => c.status != "sold" && c.startDate > new Date().toISOString()) 
    : null

  return (
    <Layout location={location}>
      <Seo
        title={course.meta?.title || course.title}
        metaKeywords={course.meta?.keywords ? course.meta.keywords : []}
        metaDescription={course.meta?.description || course.description}
        metaElements={course.ogImage ? [
          {
            name: "image",
            property: "og:image",
            content: course.ogImage
          },
          {
            name: "twitter:image",
            content: course.ogImage
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
        ] : []}
      />

      {/* Header */}
      <section className="pb-22">
        <div className="flex flex-col gap-2 xl:flex-row xl:items-center xl:gap-9">
          <a id="top"><h1 className="flex-none mt-10 md:text-3xl md:mt-3 xl:mt-0">{course.title}</h1></a>
          <span className="hidden md:flex md:flex-row md:gap-2">
            {course.top ?
              <div className="flex-none border-2 border-rose-400 px-4.5 py-2 font-bold uppercase text-rose-400 duration-200 hover:bg-rose-400 hover:text-white">
                <Icon id="gem" size={20} className="inline -ml-2 mr-2 -mt-2" />BEST SELLER
              </div> : <></>
            }
            {course.mode && course.mode === "online" ?
              <div className="flex-none border-2 border-rose-400 px-4.5 py-2 font-bold uppercase text-rose-400 duration-200 hover:bg-rose-400 hover:text-white">
                Online
              </div> : <></>
            }
            {course.type && !course.top ?
              <div className="flex-none border-2 border-rose-400 px-4.5 py-2 font-bold uppercase text-rose-400 duration-200 hover:bg-rose-400 hover:text-white">
                {course.type}
              </div> : <></>
            }
            <div className="flex-none border-2 border-rose-400 bg-rose-400 px-4.5 py-2 font-bold uppercase duration-200 text-white">
              {course.duration}
            </div>
            <div className="flex-none border-2 border-rose-400 px-4.5 py-2 font-bold uppercase text-rose-400 duration-200 hover:bg-rose-400 hover:text-white">
              <Icon id="certificate" size={20} className="inline -ml-2 mr-2 -mt-1" />certificate
            </div>
            {course.language ?
              <div className="flex-none border-2 border-rose-400 px-4.5 py-2 font-bold uppercase text-rose-400 duration-200 hover:bg-rose-400 hover:text-white">
                {course.language == "English" ?
                  <Icon id="uk-flag" size={25} className="inline" alt={course.language} /> :
                  <>{course.language}</>
                }
              </div> : <></>
            }
          </span>
        </div>

        <div className="flex flex-col mt-6 gap-11 lg:flex-row">
          <div className="lg:flex-1">
            <Rating rating={5} style="blue" size={50} className="flex items-center gap-x-3" />
            <p className="mt-16">{course.description}</p>
          </div>
          <div className="lg:max-w-lg lg:flex-1">
            <CourseBackground image={course.background} />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-13 gap-1 md:flex-row md:flex-wrap xl:justify-between">
          <span className="flex flex-col w-full gap-9 md:flex-row xl:w-auto">

            {course.prices && course.prices.length > 0 ?
              <a
                ref={buttonRef}
                className="flex max-h-22.5 flex-1 items-center justify-center bg-rose-400 p-9 text-2xl font-bold uppercase text-white duration-200 hover:bg-rose-500 xl:flex-none"
                href="#pricing">
                Book Now
              </a> : <></>
            }

            {course.dates && course.dates.length > 0 ?
              <a
                className="flex max-h-22.5 flex-1 items-center justify-center bg-sky-900 p-9 text-base font-bold uppercase text-white duration-200 hover:bg-sky-900"
                href="#calendar">
                Check Dates
              </a> : <>
                <a
                  className="grid h-22.5 place-content-center bg-sky-900 px-10 text-2xl font-bold uppercase text-white duration-200 hover:bg-sky-900 md:self-end whitespace-nowrap"
                  href="#outline">
                  Detailed Outline
                </a>
                <a
                  className="grid h-22.5 place-content-center border-2 border-rose-400 px-10 text-2xl font-bold uppercase text-rose-400 hover:text-white duration-200 whitespace-nowrap hover:bg-rose-500 md:self-end"
                  href="/contact/">
                  Ask a question
                </a>
              </>

            }

          </span>

          {firstCourse ?
            <>
              <a href="#calendar">
                <span className="flex items-center gap-x-2 text-neutral-800 sm:mt-4 md:mt-6 lg:mt-0 md:-ml-12 xl:ml-0">
                  <Icon id="calendar" size={64} />
                  <span className="flex flex-col leading-none">
                    <span className="text-xl">Next public session</span>
                    <span className="text-4.5xl font-bold">{new Intl.DateTimeFormat('de-DE', {}).format(new Date(firstCourse.startDate))}</span>
                  </span>
                </span>
              </a>
              <span className="flex items-center gap-x-2 -ml-8 md:ml-6 sm:mt-4 md:mt-6 lg:mt-0 md:-mb-4">
                <Icon id="clock" size={64} />
                <span className="flex flex-col gap-y-1 font-digital text-neutral-800">
                  {/* TODO: fix time zone calc based on DST status */}
                  <span className="text-2xl text-gray-200">08:00 GMT</span>
                  <span className="text-2xl">09:00 CET</span>
                  <span className="text-2xl text-gray-200">10:00 EET</span>
                </span>
              </span>
            </> : <></>
          }

        </div>
      </section>
      {/* Outline */}
      {course.outline && course.outline.length > 0 ?
        <div className="bg-gray-100 fullbleed">
          <section className="text-black container-alt pt-13 pb-25">
            <h2 className="text-3xl text-center capitalize mb-20"><a id="outline">What's Inside</a></h2>
            <div className="md:ml-[1.8rem] lg:ml-[3.8rem] xl:ml-0">
              {course.outline.map((section, sectionIndex) => {
                return (
                  <article key={sectionIndex}>
                    <h3 className="flex items-center mt-8 text-2xl gap-x-12 md:mt-3">
                      <img className="hidden md:block" alt="O" width="50" height="50" src={Circle} /> {section.subtitle}
                    </h3>
                    <ul className="mt-3 space-y-6 pt-4.5 pb-5 text-xl md:ml-5.5 md:border-l-6 md:border-stone-300 md:pl-17.5 xl:w-[calc(100%+32px)] ">
                      {section.modules.map((module, moduleIndex) => {
                        return (
                          <li key={moduleIndex} dangerouslySetInnerHTML={{ __html: module }} />
                        )
                      })}
                    </ul>
                  </article>
                )
              })}
            </div>
          </section>
        </div> : <></>
      }

      {/* Target audience */}
      {course.targetAudience && course.targetAudience.length > 0 ?
        <section className="container space-y-9">
          <div className="pb-24 fullbleed bg-sky-900 pt-13">
            <a id="audience"><h2 className="text-center text-white capitalize">Who's This For</h2></a>
            <article className="flex flex-col items-center gap-4 mx-auto text-center mt-9 max-w-[55.5rem] md:flex-row md:text-left">
              <div className="grid flex-none -mt-4 place-content-center">
                <Icon id="target-light" size={100} />
              </div>
              {/* TODO: convert to list of items */}
              <p className="text-white capitalize">{course.targetAudience.join(', ')}.</p>
            </article>
          </div>
        </section> : <></>
      }

      {/* Goodies */}
      {course.goodies && course.goodies.length > 0 ?
        <section className="pt-20 pb-24">
          <a id="goodies"></a>
          <h2 className="text-center capitalize">What You Get</h2>
          <div className="mx-auto mt-14 max-w-220 space-y-9">
            {course.goodies.map((goodie, goodieIndex) => {
              return (
                <article key={goodieIndex} className="flex flex-col items-center gap-4 text-center md:flex-row md:pl-4 lg:pl-2.5 md:gap-8 md:text-left">
                  <div className="grid flex-none w-20 h-20 -mt-4 place-content-center">
                    <Icon id={goodie.icon} size={80} />
                  </div>
                  <p className="text-black" dangerouslySetInnerHTML={{ __html: goodie.text }} />
                </article>
              )
            })}
          </div>
        </section> : <></>
      }

      {/* Materials */}
      {course.materials ?
        <div className="bg-gray-100 fullbleed pt-11 pb-22">
          <section className="container">
            <h2 className="text-center">All Materials Are Included</h2>
            <p className="text-center">Slides, code, snippets, cheatsheets etc.</p>
            <img className="mx-auto mt-16" src={course.materials.image} alt="materials" srcSet={course.materials.image2x + " 2x"} width="956" height="641" />
          </section>
        </div>
        : <></>
      }

      {/* Testimonials */}
      {course.testimonials && course.testimonials.length > 2 ?
        <div className="pt-8 bg-gray-100 fullbleed mt-27 pb-22">
          <section className="container">
            <h2 className="text-center capitalize"><a id="testimonials">What People Say</a></h2>
            <div className="mt-16 space-y-19">
              {course.testimonials.map((testimonial, testimonialIndex) => {
                return (
                  <Testimonial
                    key={testimonialIndex}
                    text={testimonial.text}
                    rating={testimonial.rating}
                    personImage={testimonial.person.image}
                    personTitle={testimonial.person.title}
                    personName={testimonial.person.name}
                  />
                )
              })}
            </div>
          </section>
        </div> : <></>
      }

      {/* Photos */}
      {course.photos && course.photos.length > 0 ?
        <div className="pt-8 bg-gray-100 fullbleed mt-27 pb-22">
          <section className="container mt-14">
            <h2 className="text-center">How it Happened</h2>
            <div className="grid mt-16 justify-items-center gap-y-6 md:gap-x-13 md:gap-y-10 md:grid-cols-2 max-w-[880px] mx-auto">
              {course.photos.map((photo, photoIndex) => {
                return (
                  <figure key={photoIndex}>
                    <img src={photo.image} alt={photo.caption} srcSet={photo.image2x + " 2x"} />
                    <figcaption className="mt-1.5 text-black">{photo.caption}</figcaption>
                  </figure>
                )
              })}
            </div>
          </section>
        </div>
        : <></>
      }

      {/* Trainer */}
      <div className="fullbleed mt-36 bg-sky-900 lg:overflow-hidden">
        <section className="container md:grid md:grid-cols-9 md:gap-x-20 md:pl-0 lg:gap-x-28 xl:gap-x-8">
          <div className="relative h-full fullbleed md:fullbleed-none md:col-span-4 md:col-start-1 md:row-start-1">
            <img className="object-cover w-full h-full mt-16 md:absolute md:inset-0 md:mt-0" src="/images/andrey-adamovich-02.jpg" alt="Andrey Adamovich" srcSet="/images/andrey-adamovich-02@2x.jpg 2x" width="375" height="307" loading="lazy" />
          </div>
          <div className="grid pt-16 pb-20 text-center justify-items-center md:col-span-5 md:col-start-5 md:row-start-1 xl:col-span-4 xl:col-start-6 xl:pt-25 xl:pb-22 md:text-left md:justify-items-start">
            <h2 className="flex flex-col gap-x-2.5 text-3xl capitalize text-white md:flex-row items-center">
              <Icon id="trainer-light" size={40} />
              About the trainer
            </h2>
            <p className="mt-7 text-white/60">
              Andrey works as a free-lance DevOps consultant offering his expertise in implementing DevOps
              initiatives, selecting automation tooling, switching to infrastructure-as-code and immutable
              infrastructure and constructing software delivery pipelines.
            </p>
            <Link
              to="/contact/"
              className="font-bold text-white uppercase duration-200 border-2 border-white mt-9 px-14 py-7 hover:bg-white hover:text-sky-900">
              Ask a question
            </Link>
          </div>
        </section>
      </div>

      {/* Calendar */}
      {course.dates && course.dates.length > 0 ?
        <section className="mt-40 bg-gray-100 fullbleed overflow-x-clip">
          <a id="calendar"></a>
          <h2 className="text-3xl text-center capitalize pt-10">When Does it Happen</h2>
          <div className="container relative pt-7 pb-16">
            {course.dates.slice(-4).map((date, dateIndex) => {
              const dateStr =
                date.startDate.substr(8, 2) + "-" +
                date.endDate.substr(8, 2) + " " +
                new Intl.DateTimeFormat('en-US', { month: "long" }).format(new Date(date.startDate)) + ", " +
                date.startDate.substr(0, 4)
              return (
                <div key={dateIndex} className="relative flex flex-wrap mt-9 items-center gap-1 md:gap-x-3.5">
                  <Icon id="calendar" size={60} />
                  {date.status === "sold" ?
                    <>
                      <del className="text-neutral-800/30 text-xl font-bold md:text-4.5xl">{dateStr}</del>
                      <img src={Sold} className="absolute right-0 scale-65 md:static md:scale-100" alt="sold out" width="112" height="104" />
                    </>
                    :
                    <>
                      <span className="text-neutral-800 text-xl font-bold md:text-4.5xl">{dateStr}</span>
                      {date.status === "few" ?
                        <span
                          className="absolute right-0 px-2 py-1 font-bold uppercase border-2 md:py-2 md:px-4 text-xxs md:text-base border-rose-400 text-rose-400 md:static md:ml-6">
                          few seats left
                        </span>
                        :
                        <></>
                      }
                    </>
                  }
                </div>
              )
            })}

            <svg className="hidden lg:absolute lg:-right-1/4 lg:top-[-7.3rem] lg:block xl:right-0 xl:top-[-7.3rem]" role="presentation" width="662" height="662" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#a)" fill="#000" fillOpacity=".1">
                <path d="m256.858 586.546 313.891-140.129a46.877 46.877 0 0 0 23.695-61.912L479.792 127.684a46.874 46.874 0 0 0-61.912-23.694l-28.535 12.739-12.739-28.536-28.536 12.739 38.217 85.607 28.536-12.739-12.739-28.536 28.535-12.739a15.629 15.629 0 0 1 20.638 7.898l12.739 28.536-342.427 152.869-12.739-28.536a15.626 15.626 0 0 1 7.898-20.637l28.536-12.739 12.739 28.535 28.535-12.739-12.739-28.535 185.482-82.804-12.739-28.536-185.482 82.804-12.739-28.535-28.535 12.739 12.739 28.535-28.536 12.739a46.875 46.875 0 0 0-23.695 61.912l19.109 42.804a15.625 15.625 0 0 0 20.637 7.898l356.695-159.238 89.173 199.749a15.626 15.626 0 0 1-7.898 20.637l-313.891 140.13a15.629 15.629 0 0 1-20.638-7.898l-82.803-185.482-28.536 12.739 82.804 185.482a46.874 46.874 0 0 0 61.912 23.694Z" />
                <path d="m204.118 353.421-28.535 12.739 12.739 28.535 28.535-12.739-12.739-28.535ZM289.725 315.203l-28.536 12.739 12.739 28.536 28.536-12.739-12.739-28.536ZM375.331 276.987l-28.535 12.739 12.739 28.535 28.536-12.739-12.74-28.535ZM460.938 238.769l-28.535 12.739 12.739 28.536 28.535-12.739-12.739-28.536ZM229.596 410.492l-28.535 12.739 12.739 28.536 28.535-12.74-12.739-28.535ZM315.203 372.275l-28.536 12.739 12.74 28.535 28.535-12.739-12.739-28.535ZM400.809 334.057l-28.535 12.739 12.739 28.536 28.536-12.739-12.74-28.536ZM486.416 295.841l-28.535 12.739 12.739 28.535 28.535-12.739-12.739-28.535ZM255.074 467.563l-28.535 12.739 12.739 28.536 28.535-12.739-12.739-28.536ZM340.681 429.346l-28.535 12.739 12.739 28.536 28.535-12.74-12.739-28.535ZM426.288 391.129l-28.536 12.739 12.739 28.535 28.536-12.739-12.739-28.535ZM511.894 352.911l-28.535 12.739 12.739 28.536 28.535-12.739-12.739-28.536Z" />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" transform="rotate(-24.057 480.57 100.43)" d="M0 0h500v500H0z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </section> : <></>
      }

      {/* Pricing */}
      {course.prices && course.prices.length > 0 ?
        (course.prices.length == 1 ?
          <section ref={pricesRef} className="max-w-[1114px] pt-24 pb-32 text-black mx-auto lg:gap-20 lg:flex">
            <div className="w-full lg:w-[350px]">
              <h2 className="text-3xl text-center"><a id="pricing">Pricing</a></h2>
              {course.prices.map((price, priceIndex) => {
                return (
                  <PriceBox
                    key={priceIndex}
                    name={price.name}
                    comment1={price.comment1}
                    comment2={price.comment2}
                    price={price.price}
                    oldPrice={price.oldPrice}
                    bookingLink="https://nethunt.com/service/automation/forms/61eaef229d5fe3be2d7c709c"
                  />
                )
              })}
            </div>

            <div className="flex flex-col justify-between flex-1 pb-8 mt-16 lg:mt-32">
              <div className="text-neutral-400 space-y-7">
                <p>Price includes all materials and cloud infrastructure usage.</p>
                <p>If training workshop happens on client premises then trainer's <strong>traveling expenses</strong> will be added to the final price.</p>
              </div>
              <div className="text-neutral-400 space-y-7 mt-7 lg:mt-0">
                <p><strong>VAT</strong> charges may apply.</p>
                <p className="mt-4">All training courses are a subject to the <Link to="/cancel/"><strong>cancellation</strong></Link> policy.</p>
              </div>
            </div>

          </section>

          :

          <section ref={pricesRef} className="max-w-[1114px] pt-24 text-black pb-32 xl:ml-auto">
            <h2 className="text-3xl text-center"><a id="pricing">Pricing</a></h2>
            <div className="mt-16 gap-7 flex flex-col lg:flex-row lg:justify-between">
              {course.prices.map((price, priceIndex) => {
                return (
                  <PriceBox
                    key={priceIndex}
                    name={price.name}
                    comment1={price.comment1}
                    comment2={price.comment2}
                    price={price.price}
                    oldPrice={price.oldPrice}
                    bookingLink="https://nethunt.com/service/automation/forms/61eaef229d5fe3be2d7c709c"
                  />
                )
              })}
            </div>
            <div className="text-neutral-400 md:mt-4 md:flex md:justify-between">
              <p className="mt-4"><strong>VAT</strong> charges may apply.</p>
              <p className="mt-4">All training courses are a subject to the <Link to="/cancel/"><strong>cancellation</strong></Link> policy.</p>
            </div>
          </section>
        ) : <></>
      }

      <StickySection isVisible={isButtonInView || isPricesInView} />

      <div className="mb-20">
        <CourseBackground image={course.background} />
      </div>


      {/* TODO: add related courses section */}

    </Layout >
  )
}

export default CourseTemplate

export const pageQuery = graphql`
  query CourseById(
    $id: String!
  ) {
    coursesYaml(id: { eq: $id }) {
      id
      slug
      title
      top
      type
      mode
      abstract
      language
      description
      tags
      duration
      background
      ogImage
      meta {
        keywords
        title
        description
      }
      photos {
        image
        image2x
        caption
      }
      materials {
        image
        image2x
      }
      goodies {
        icon
        text
      }
      outline {
        subtitle
        modules
      }
      testimonials {
        text
        rating
        person {
          name
          title
          image
        }
      }
      dates {
        startDate
        endDate
        status
        time
      }
      prices {
        name
        oldPrice
        price
        comment1
        comment2
      }
      targetAudience
    }
  }
`
